import { inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable, of, EMPTY } from 'rxjs';
import {
  catchError,
  map,
  shareReplay,
  skipWhile,
  switchMap,
  tap,
  expand,
  reduce,
  delay,
} from 'rxjs/operators';

import { CmmApiService } from './helpers/cmm-api.service';
import { SimpleError } from './helpers/logging.service';
import { AuthService } from './auth.service';
import { FormLoginService } from './forms/form-login.service';
import { ConsoleUser } from '@helpers/types/user';

type OAuthProvider = {
  id: number;
  user_id: number;
  provider: string;
  provider_id: string;
  name: string;
  nickname: string | null;
  email: string;
  telephone: string | null;
  avatar_path: string | null;
  token: string;
};

export type NotificationMessageCategory = {
  id: number;
  name: string;
  icon: string;
  created_at?: string;
  updated_at?: string;
};

export type BasecampMessage = {
  id?: number;
  subject: string;
  status: 'active';
  content?: string;
  category_id?: number;
  subscriptions?: number[];
};

export type BasecampProfile = {
  id: number;
  attachable_sgid: string;
  name: string;
  email_address: string;
  personable_type: string;
  title: string;
  bio: string;
  created_at: string;
  updated_at: string;
  admin: boolean;
  owner: boolean;
  client: boolean;
  time_zone: string;
  avatar_url: string;
  avatar_kind: string;
  company: {
    id: number;
    name: string;
  };
};

export type BasecampProject = {
  id: number;
  status: 'active' | 'archived' | 'trashed';
  created_at: string;
  updated_at: string;
  name: string;
  description: string;
  purpose: string;
  clients_enabled: boolean;
  bookmark_url: string;
  url: string;
  app_url: string;
  dock: any[];
  archived: boolean;
  trashed: boolean;
  playgrounded: boolean;
  edit_tools_app_url: string;
  active_status_app_url: string;
  trashed_status_app_url: string;
  people: any;
  bookmarked: boolean;
  subscribed: boolean;
};

export type BasecampUpdatedProfiles = {
  granted?: BasecampProfile[];
  revoked?: BasecampProfile[];
};

export type BasecampProjectUpdatedProfiles = {
  id: number;
  updated: BasecampUpdatedProfiles;
};

@Injectable({
  providedIn: 'root',
})
export class BasecampService extends CmmApiService {
  protected override serviceName = 'BasecampService';

  private oAuthURL = CmmApiService.API_URL + '/auth/token/oauth';
  private basecampAPIURL = CmmApiService.API_URL + '/basecamp';
  private basecampNotificationURL =
    CmmApiService.API_URL + '/basecamp-categories/notifications';
  private basecampUsersURL = CmmApiService.API_URL + '/basecamp-users';

  private auth = inject(AuthService);
  private formLogin = inject(FormLoginService);

  constructor() {
    super();

    this.forceBasecampLogin();
  }

  private forceBasecampLogin() {
    this.getBasecampOauthToken().subscribe((token) => {
      if (!token) {
        let obsForm = this.formLogin.open(
          'Please authenticate with Basecamp.\nThis will allow us' +
            ' to provide more features in the CMM Console and CreekCamp extension.',
        );

        obsForm.subscribe((form) => {
          form
            .afterClosed((result) => {
              if (!result) return of(false);

              window.location.href = `${CmmApiService.API_ORIGIN}/user/profile#connections`;
              return of(true);
            })
            .pipe(shareReplay(1));
        });
      }
    });
  }

  private obsGetNotificationMessageCategories$: Observable<
    NotificationMessageCategory[] | null
  > | null = null;
  getNotificationMessageCategories(): Observable<
    NotificationMessageCategory[] | null
  > {
    //TODO: Fix how this gets the message categories to work with "message_boards"
    const url = `${this.basecampNotificationURL}`;

    if (this.obsGetNotificationMessageCategories$ === null) {
      this.obsGetNotificationMessageCategories$ =
        this.getBasecampOauthToken().pipe(
          switchMap((token) => {
            if (token) {
              return this.http.get<NotificationMessageCategory[]>(
                url,
                this.httpOptions,
              );
            } else {
              return of([]);
            }
          }),
          tap((_) => this.log(`fetched Notification Message Categories`)),
          catchError(
            this.handleError<NotificationMessageCategory[]>(
              `getNotificationMessageCategories`,
            ),
          ),
          shareReplay(1),
          this.trace('getNotificationMessageCategories'),
        );
    }

    return this.obsGetNotificationMessageCategories$;
  }

  private obsGetBasecampOauthToken$: Observable<OAuthProvider | null> | null =
    null;
  getBasecampOauthToken(): Observable<OAuthProvider | null> {
    if (!this.obsGetBasecampOauthToken$) {
      const url = this.oAuthURL;

      this.obsGetBasecampOauthToken$ =
        this.auth.isAuthenticated$?.pipe(
          skipWhile((loggedIn) => !loggedIn),
          switchMap((_) => {
            return this.http.get<OAuthProvider[]>(url, this.httpOptions).pipe(
              map((tokens) => {
                const providers = tokens as OAuthProvider[];
                return (
                  providers.find((t) => t.provider === '37signals') ?? null
                );
              }),
              tap((token) => {
                if (!token) {
                  throw new SimpleError('No Basecamp Token Found');
                }
                this.log(`retreived Basecamp OAuth token`);
              }),
              catchError(
                this.handleError<OAuthProvider>(`getBasecampOauthToken`),
              ),
              this.trace('getBasecampOauthToken'),
            );
          }),
          shareReplay(1),
        ) ?? of(null);
    }

    return this.obsGetBasecampOauthToken$;
  }

  addMessageToBoard(
    bucket: number,
    board: number,
    messageData: BasecampMessage,
  ) {
    const url = `${this.basecampAPIURL}/buckets/${bucket}/message_boards/${board}/messages.json`;

    return this.http
      .post<BasecampMessage>(url, messageData, this.httpOptions)
      .pipe(
        tap((_) => this.log(`added Basecamp message: ${messageData.subject}`)),
        catchError(this.handleError<BasecampMessage>(`addMessageToBoard`)),
        this.trace('addMessageToBoard'),
      );
  }

  private obsGetProfile$: Observable<BasecampProfile | null> | null = null;
  getProfile(): Observable<BasecampProfile | null> {
    if (!this.obsGetProfile$) {
      const url = `${this.basecampAPIURL}/my/profile.json`;

      this.obsGetProfile$ = this.getBasecampOauthToken().pipe(
        switchMap((_) => this.http.get<BasecampProfile>(url, this.httpOptions)),
        tap((profile) => {
          if (!profile) {
            this.log('Basecamp profile not retreived');
            return;
          }
          this.log(`retrieved Basecamp profile: ${profile.name}`);
        }),
        catchError(this.handleError<BasecampProfile>(`getProfile`)),
        this.trace('getProfile'),
        shareReplay(1),
      );
    }

    return this.obsGetProfile$;
  }

  getUserProjects(userCmmID: string): Observable<BasecampProject[] | null> {
    //return of<BasecampProject[]>(tempProjectData[id] as BasecampProject[]);

    const url = `${this.basecampUsersURL}/${userCmmID}/basecamps`;

    let page = 1;
    let status: '' | 'archived' | 'trashed' = '';

    // TODO: Add archived and trashed basecamps with ?status=...

    return this.http
      .get<BasecampProject[]>(url, { observe: 'response', ...this.httpOptions })
      .pipe(
        expand<
          HttpResponse<BasecampProject[]>,
          Observable<HttpResponse<BasecampProject[]>>
        >((res) => {
          if (res.body?.length || status !== 'trashed') {
            if (!res.body?.length) {
              if (status === '') {
                status = 'archived';
                page = 0;
              } else if (status === 'archived') {
                status = 'trashed';
                page = 0;
              }
            }

            return this.http.get<BasecampProject[]>(
              `${url}?page=${++page}&status=${status}`,
              {
                observe: 'response',
                ...this.httpOptions,
              },
            );
          } else {
            return EMPTY;
          }
        }),
        reduce<HttpResponse<BasecampProject[]>, BasecampProject[]>(
          (acc, res) => {
            if (!res.body) return acc;
            return acc.concat(res.body);
          },
          [],
        ),
        tap((_) => this.log(`retrieved Basecamp projects for: ${userCmmID}`)),
        catchError(this.handleError<BasecampProject[]>(`getUserProjects`)),
        this.trace('getUserProjects'),
      );
  }

  assignUserToProjects(
    userFrom: ConsoleUser,
    projects: BasecampProject[],
    userTo: ConsoleUser,
  ): Observable<BasecampProjectUpdatedProfiles[] | null> {
    let index = 0;

    const url = `${this.basecampUsersURL}/${userFrom.cmm_id}/basecamps`;

    const assignData = {
      grant: [userTo.basecamp_id],
    };

    // TODO: Convert this to Queued Jobs on the server

    return this.http
      .put<BasecampProjectUpdatedProfiles>(
        `${url}/${projects[index].id}`,
        assignData,
        {
          observe: 'response',
          ...this.httpOptions,
        },
      )
      .pipe(
        expand<
          HttpResponse<BasecampProjectUpdatedProfiles>,
          Observable<HttpResponse<BasecampProjectUpdatedProfiles>>
        >((res) => {
          if (index >= projects.length - 1) return EMPTY;

          const requestObs = this.http.put<BasecampProjectUpdatedProfiles>(
            `${url}/${projects[++index].id}`,
            assignData,
            {
              observe: 'response',
              ...this.httpOptions,
            },
          );

          let delayAmount = 1500;

          let rateCMMJSON = res.headers.get('X-RateLimit-Remaining');
          if (rateCMMJSON === null) {
            return EMPTY;
          }
          if (parseInt(rateCMMJSON) <= 5) {
            delayAmount += 30000;
          }

          let rateBasecampJSON = res.headers.get('X-Basecamp-X-Ratelimit');
          if (!rateBasecampJSON) {
            // an error occurred getting the rate data
            this.loggingService.log(
              this.serviceName,
              'No Basecamp API proxy rate limit found.',
            );
            return EMPTY;
          }

          let basecampRateLimitData = null;
          try {
            basecampRateLimitData = JSON.parse(rateBasecampJSON.split(', ')[0]);
          } catch (e) {
            this.loggingService.log(
              this.serviceName,
              'Error parsing Basecamp API proxy rate limit.',
            );
            return EMPTY;
          }

          if (
            basecampRateLimitData &&
            basecampRateLimitData['remaining'] <= 5 &&
            delayAmount < 5000
          ) {
            delayAmount += 5000;
          }

          return requestObs.pipe(delay(delayAmount));
        }),
        reduce<
          HttpResponse<BasecampProjectUpdatedProfiles>,
          BasecampProjectUpdatedProfiles[]
        >((acc, res) => {
          if (!res.body) return acc;
          return acc.concat(res.body);
        }, []),
        tap((_) =>
          this.log(
            `assigned Basecamp projects from: ${userFrom.name} to: ${userTo.name}`,
          ),
        ),
        catchError(
          this.handleError<BasecampProjectUpdatedProfiles[]>(
            `assignUserToProjects`,
          ),
        ),
        this.trace('assignUserToProjects'),
      );
  }
}
