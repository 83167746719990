import { Injectable } from '@angular/core';
import {
  Business,
  BusinessBrand,
  BusinessCategory,
  BusinessDomain,
  BusinessLocation,
  BusinessService,
  BusinessTiny,
  CmsPlatform,
  ServiceCategory,
} from '@helpers/types/client';
import { ConsoleUser } from '@helpers/types/user';
import {
  FilterSelectionValue,
  FilterTextValue,
} from '@shared/components/table-filter/table-filter.component';
import {
  defaultIfEmpty,
  forkJoin,
  map,
  Observable,
  of,
  shareReplay,
  tap,
} from 'rxjs';
import { ClientService, FilterParameters } from './client.service';
import { UserService } from './user.service';

type ColumnDetailsBasic<T> = {
  columnName: string | string[];
  dbColumns: (keyof Business)[];

  doPreloadData?: Observable<any>;
  preloadData?: any;
  generateCellData: (business: Business, preloadData?: any) => T;

  sortColumns?: string[];

  width?: number;
  minWidth?: number;
  maxWidth?: number;
};

type ColumnNames = {
  [key: string]: { columnName: string | string[] };
};

export type ColumnDetailsNoFilter<T> = {
  filterMode?: never;
  generateFilter?: never;
  generateFilterOptions?: never;
  filterOptions?: never;
} & ColumnDetailsBasic<T>;
export type ColumnDetailsTextFilter<T> = {
  filterMode: 'text';
  generateFilter: (
    filter: FilterTextValue,
    preloadData: any,
  ) => FilterParameters;
  generateFilterOptions?: undefined;
  filterOptions?: undefined;
} & ColumnDetailsBasic<T>;
export type ColumnDetailsSelectFilter<T> = {
  filterMode: 'select';
  generateFilter: (filter: string[], preloadData: any) => FilterParameters;
  generateFilterOptions?: (preloadData?: any) => FilterSelectionValue[];
  filterOptions?: FilterSelectionValue[];
} & ColumnDetailsBasic<T>;

export type ColumnDetails<T> =
  | ColumnDetailsNoFilter<T>
  | ColumnDetailsTextFilter<T>
  | ColumnDetailsSelectFilter<T>;

@Injectable({
  providedIn: 'root',
})
export class ClientColumnDataService {
  private CLIENT_COLUMN_MAP: Record<string, ColumnDetails<any>> = {};

  constructor(
    private clientService: ClientService,
    private userService: UserService,
  ) {
    this.generateColumns();
  }

  private addColumn<Type>(name: string, data: ColumnDetails<Type>) {
    if (this.CLIENT_COLUMN_MAP[name]) {
      throw Error('Client column already created: ' + name);
    }

    this.CLIENT_COLUMN_MAP[name] = data as ColumnDetails<Type>;
  }

  private sortByName(
    a: any & { name: string },
    b: any & { name: string },
  ): number {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  }

  public getColumnKeys(): string[] {
    return Object.keys(this.CLIENT_COLUMN_MAP);
  }

  public getColumnNames(): ColumnNames {
    return Object.keys(this.CLIENT_COLUMN_MAP).reduce((prev, curr) => {
      prev[curr] = { columnName: this.CLIENT_COLUMN_MAP[curr].columnName };
      return prev;
    }, {} as ColumnNames);
  }

  public getRequiredDBColumns(column: string): (keyof Business)[] {
    return this.CLIENT_COLUMN_MAP[column].dbColumns;
  }

  private obsGetAllColumnData: Observable<{
    [key: string]: ColumnDetails<any>;
  }> | null = null;
  public getAllColumnData(): Observable<Record<string, ColumnDetails<any>>> {
    if (!this.obsGetAllColumnData) {
      this.obsGetAllColumnData = new Observable<
        Record<string, ColumnDetails<any>>
      >((observer) => {
        let preloadDataObservers: Observable<any>[] = [];

        // TODO: Only preload required data based on visible columns
        Object.keys(this.CLIENT_COLUMN_MAP).forEach((key) => {
          if (this.CLIENT_COLUMN_MAP[key].doPreloadData) {
            preloadDataObservers.push(
              this.CLIENT_COLUMN_MAP[key].doPreloadData?.pipe(
                tap(
                  (preloadData) =>
                    (this.CLIENT_COLUMN_MAP[key].preloadData = preloadData),
                ),
              ) ?? of(null),
            );
          }
        });

        forkJoin(preloadDataObservers)
          .pipe(defaultIfEmpty(null))
          .subscribe(() => {
            Object.keys(this.CLIENT_COLUMN_MAP).forEach((key) => {
              const column = this.CLIENT_COLUMN_MAP[key];

              if (column.filterMode !== 'select') return;

              if (!column.generateFilterOptions) return;

              column.filterOptions = column.generateFilterOptions(
                column.preloadData,
              );
            });

            observer.next(this.CLIENT_COLUMN_MAP);
            observer.complete();
          });
      }).pipe(shareReplay(1));
    }

    return this.obsGetAllColumnData;
  }

  private filterRegexText(filter: FilterTextValue, content: string): boolean {
    if (filter?.value) {
      let filterText = filter.value;
      if (!filter.caseSensitive) {
        content = content.toLowerCase();
        filterText = filterText.toLowerCase();
      }

      if (filter.regex) {
        try {
          let regex = new RegExp(filterText);
          if (!content.match(regex)) return false;
        } catch (e) {
          //regular expression error, just return true
          return true;
        }
      } else if (!content.includes(filterText)) {
        return false;
      }
    }

    return true;
  }

  /*
  ===============================================================================================================
  ===============================================================================================================
  ===============================================================================================================
  ===============================================================================================================
  ===============================================================================================================
  */

  // TODO: generateFilterOptions() function should have type generics

  // TODO: Include all filters in API

  /**
   * Generates all the table (client list) column data
   */
  private generateColumns(): void {
    this.addColumn<string>('name', {
      columnName: 'Name',
      dbColumns: ['name'],
      generateCellData: (business) => business.name,
      filterMode: 'text',
      generateFilter: (filter, cellData) => ({
        name: [filter.value],
      }),
      // TODO: Enable Regex filtering in API
      //this.filterRegexText(filter, cellData),
      sortColumns: ['name'],
      minWidth: 250,
    });

    this.addColumn<string[]>('domain_favicon', {
      columnName: 'Favicon',
      dbColumns: ['domains'],
      filterMode: 'select',
      generateFilter: (filter) => ({
        'domains.favicon': filter,
      }),
      generateFilterOptions: () => [
        {
          name: 'Favicon',
          value: 'exists',
        },
        {
          name: 'Missing',
          value: 'empty',
        },
      ],
      generateCellData: (business) => {
        return business.domains
          ?.map((dom) => dom.favicon ?? '')
          .filter((f) => !!f);
      },
      width: 75,
      minWidth: 75,
      maxWidth: 75,
    });

    this.addColumn<BusinessDomain[]>('domain', {
      columnName: 'Domain',
      dbColumns: ['domains'],
      generateCellData: (business) => business.domains ?? [],
      filterMode: 'text',
      generateFilter: (filter) => ({
        // TODO: RegexFilter in API
        'domains.domain': [filter.value],
      }),
      minWidth: 250,
    });

    this.addColumn<string[]>('domain_cms', {
      columnName: 'CMS',
      dbColumns: ['domains'],
      doPreloadData: this.clientService
        .getCmsPlatforms()
        .pipe(map((platforms) => platforms.sort(this.sortByName))),
      generateCellData: (business, preloadData) => {
        return (
          business.domains?.map((dom) => {
            return (
              (preloadData as CmsPlatform[]).find(
                (p) => p.id === dom.cms_platform_id,
              )?.name ?? ''
            );
          }) ?? []
        );
      },
      filterMode: 'select',
      generateFilter: (filter) => ({
        'domains.cms_platform_id': filter,
      }),
      generateFilterOptions: (preloadData) => {
        return (preloadData as CmsPlatform[]).map<FilterSelectionValue>(
          (cms) => {
            return {
              name: cms.name,
              value: cms.id + '',
            };
          },
        );
      },
      width: 180,
    });

    this.addColumn<string[]>('team_members', {
      columnName: 'Team Members',
      dbColumns: ['team_members'],
      doPreloadData: this.userService
        .getAllUsers()
        .pipe(map((users) => users.sort(this.sortByName))),
      generateCellData: (business) => {
        // TODO: Team members need tooltip in table
        let data: string[] = [];
        business.team_members.forEach((member) => {
          data.push(member.name);
        });
        return data;
      },
      filterMode: 'select',
      generateFilter: (filter) => ({
        'team_members.id': filter,
      }),
      generateFilterOptions: (preloadData) => {
        return (preloadData as ConsoleUser[]).map<FilterSelectionValue>(
          (user) => {
            return {
              name: user.name,
              value: user.id + '',
            };
          },
        );
      },
    });

    this.addColumn<string[]>('status', {
      columnName: 'Status',
      dbColumns: ['active', 'is_paused', 'is_exclusive'],
      generateCellData: (business) => {
        let data = [];
        data.push(
          business.active
            ? business.is_paused
              ? 'Paused'
              : 'Active'
            : 'Inactive',
        );
        data.push(business.is_exclusive ? 'Exclusive' : 'Non-exclusive');
        return data;
      },
      filterMode: 'select',
      generateFilter: (filter) => {
        let filterParams: FilterParameters = {};

        let columnName: string, columnValue: boolean;

        filter.forEach((status) => {
          switch (status) {
            case 'active':
              columnName = 'active';
              columnValue = true;
              break;
            case 'paused':
              columnName = 'is_paused';
              columnValue = true;
              break;
            case 'inactive':
              columnName = 'active';
              columnValue = false;
              break;
            case 'exclusive':
              columnName = 'is_exclusive';
              columnValue = true;
              break;
            case 'nonexclusive':
              columnName = 'is_exclusive';
              columnValue = false;
              break;
          }

          if (columnName) {
            if (!filterParams[columnName]) filterParams[columnName] = [];
            (filterParams[columnName] as boolean[]).push(columnValue);
          }
        });

        return filterParams;
      },
      filterOptions: [
        {
          name: 'Active',
          value: 'active',
        },
        {
          name: 'Paused',
          value: 'paused',
        },
        {
          name: 'Inactive',
          value: 'inactive',
        },
        {
          name: 'Exclusive',
          value: 'exclusive',
        },
        {
          name: 'Non-exclusive',
          value: 'nonexclusive',
        },
      ],
      sortColumns: ['-active', 'is_paused', '-is_exclusive'],
      width: 120,
    });

    this.addColumn<string[]>('payment_status', {
      columnName: 'Payment',
      dbColumns: ['is_overdue'],
      generateCellData: (business) => {
        return [business.is_overdue ? 'Overdue' : 'Paid'];
      },
      filterMode: 'select',
      generateFilter: (filter) => {
        let filterParams: FilterParameters = {};

        let columnName: string, columnValue: boolean;

        filter.forEach((status) => {
          switch (status) {
            case 'overdue':
              columnName = 'is_overdue';
              columnValue = true;
              break;
            case 'paid':
              columnName = 'is_overdue';
              columnValue = false;
              break;
          }

          if (columnName) {
            if (!filterParams[columnName]) filterParams[columnName] = [];
            (filterParams[columnName] as boolean[]).push(columnValue);
          }
        });

        return filterParams;
      },
      filterOptions: [
        {
          name: 'Overdue',
          value: 'overdue',
        },
        {
          name: 'Paid',
          value: 'paid',
        },
      ],
      sortColumns: ['is_overdue'],
      width: 120,
    });

    this.addColumn<string[]>('business_check_client', {
      columnName: 'Is/Was Client',
      dbColumns: ['basecamp_id'],
      generateCellData: (business) => {
        return [business.basecamp_id ? 'Is/Was Client' : 'Never a Client'];
      },
      filterMode: 'select',
      generateFilter: (filter) => {
        let filterParams: FilterParameters = {};

        let columnName: string, columnValue: boolean;

        filter.forEach((status) => {
          switch (status) {
            case 'client':
              columnName = 'null_basecamp_id';
              columnValue = false;
              break;
            case 'not_client':
              columnName = 'null_basecamp_id';
              columnValue = true;
              break;
          }

          if (columnName) {
            if (!filterParams[columnName]) filterParams[columnName] = [];
            (filterParams[columnName] as boolean[]).push(columnValue);
          }
        });

        return filterParams;
      },
      filterOptions: [
        {
          name: 'Is/Was Client',
          value: 'client',
        },
        {
          name: 'Never a Client',
          value: 'not_client',
        },
      ],
      sortColumns: ['basecamp_id'],
      width: 120,
    });

    this.addColumn<string[]>('services', {
      columnName: 'Services',
      dbColumns: ['service_links'] as any[],
      doPreloadData: this.clientService
        .getServices()
        .pipe(map((services) => services.sort(this.sortByName))),
      generateCellData: (business) =>
        business.services?.map(
          (service) => service.service_details.name ?? '',
        ) ?? [],
      filterMode: 'select',
      generateFilter: (filter) => ({
        'service_links.service_id': filter,
      }),
      generateFilterOptions: (preloadData) => {
        return (preloadData as BusinessService[]).map<FilterSelectionValue>(
          (service) => {
            return {
              name: service.name,
              value: service.id + '',
            };
          },
        );
      },
    });
    this.addColumn<string[]>('service_categories', {
      columnName: 'Service Categories',
      dbColumns: ['service_links'] as any[],
      doPreloadData: forkJoin({
        serviceCategories: this.clientService
          .getServiceCategories()
          .pipe(
            map((serviceCategories) => serviceCategories.sort(this.sortByName)),
          ),
        services: this.clientService.getServices(),
      }),
      generateCellData: (business) =>
        business.service_categories?.map(
          (serviceCategory) => serviceCategory.name ?? '',
        ) ?? [],
      filterMode: 'select',
      generateFilter: (filter, preloadData) => {
        // FIXME: Service category filtering. This method is missing included/addon/excluded services
        let categoryServices = (preloadData.services as BusinessService[])
          .filter((s) =>
            (preloadData.serviceCategories as ServiceCategory[]).some((sc) =>
              s.categories.some((soc) => soc.id === sc.id),
            ),
          )
          .map((s) => s.id + '');
        return { 'service_links.service_id': categoryServices };
      },
      generateFilterOptions: (preloadData) => {
        return (
          preloadData.serviceCategories as ServiceCategory[]
        ).map<FilterSelectionValue>((serviceCategory) => {
          return {
            name: serviceCategory.name,
            value: serviceCategory.id + '',
          };
        });
      },
    });

    this.addColumn<string[]>('categories', {
      columnName: 'Industry Categories',
      dbColumns: ['categories'],
      doPreloadData: this.clientService
        .getCategories()
        .pipe(map((categories) => categories.sort(this.sortByName))),
      generateCellData: (business) =>
        business.categories?.map((category) => category.name ?? ''),
      filterMode: 'select',
      generateFilter: (filter) => ({
        'categories.id': filter,
      }),
      generateFilterOptions: (preloadData) => {
        return (preloadData as BusinessCategory[]).map<FilterSelectionValue>(
          (category) => {
            return {
              name: category.name,
              value: category.id + '',
            };
          },
        );
      },
    });

    this.addColumn<string[]>('brands', {
      columnName: 'Industry Brands',
      dbColumns: ['brands'],
      doPreloadData: this.clientService.getBrands(),
      generateCellData: (business) =>
        business.brands?.map((brand) => brand.name ?? ''),
      filterMode: 'select',
      generateFilter: (filter) => ({
        'brands.id': filter,
      }),
      generateFilterOptions: (preloadData) => {
        return (preloadData as BusinessBrand[]).map<FilterSelectionValue>(
          (brand) => {
            return {
              name: brand.name,
              value: brand.id + '',
            };
          },
        );
      },
    });

    this.addColumn<BusinessLocation[]>('location', {
      columnName: 'Location',
      dbColumns: ['locations'],
      generateCellData: (business) => business.locations,
      filterMode: 'text',
      generateFilter: (filter) =>
        // TODO: Regex in API
        ({
          'locations.city': [filter.value],
          'locations.state': [filter.value],
        }),
    });

    this.addColumn<string>('id', {
      columnName: 'ID',
      dbColumns: ['id'],
      generateCellData: (business) => business.id + '',
      filterMode: 'text',
      generateFilter: (filter, cellData) =>
        // TODO: Regex in API
        ({
          id: [filter.value],
        }),
      sortColumns: ['id'],
      width: 120,
      minWidth: 120,
      maxWidth: 120,
    });

    this.addColumn<string>('maintenance_date', {
      columnName: 'Maintenance Date',
      dbColumns: ['maintenance_date'],
      generateCellData: (business) => {
        if (business.maintenance_date) {
          return new Date(business.maintenance_date).toLocaleDateString(
            'en-US',
          );
        } else {
          return '';
        }
      },
      sortColumns: ['maintenance_date'],
      minWidth: 250,
    });

    this.addColumn<string>('report_day', {
      columnName: 'Report Day',
      dbColumns: ['report_day'],
      generateCellData: (business) =>
        business.report_day ? business.report_day + '' : '',
      filterMode: 'text',
      generateFilter: (filter, cellData) =>
        // TODO: Regex in API
        ({
          report_day: [filter.value],
        }),
      sortColumns: ['report_day'],
      width: 170,
      minWidth: 170,
      maxWidth: 170,
    });

    this.addColumn<string>('basecamp_link', {
      columnName: 'Basecamp Link',
      dbColumns: ['quick_links'],
      generateCellData: (business) => business.quick_links?.basecamp ?? '',
      filterMode: 'text',
      generateFilter: (filter, cellData) =>
        // TODO: Regex in API
        ({
          basecamp_id: [filter.value],
        }),
    });

    this.addColumn<string>('dashboard_link', {
      columnName: 'Dashboard Link',
      dbColumns: ['quick_links'],
      generateCellData: (business) =>
        business.quick_links?.analytics_dashboard ?? '',
      filterMode: 'text',
      generateFilter: (filter, cellData) =>
        // TODO: Regex in API
        ({
          analytics_dashboard: [filter.value],
        }),
    });

    this.addColumn<BusinessTiny[]>('waitlist', {
      columnName: 'Waitlist',
      dbColumns: ['waitlist'],
      generateCellData: (business) => business.waitlist,
      filterMode: 'text',
      generateFilter: (filter) =>
        // TODO: Regex in API
        ({
          'waitlist.name': [filter.value],
        }),
    });

    this.addColumn<BusinessTiny[]>('competitors', {
      columnName: 'Competitors',
      dbColumns: ['competitors'],
      generateCellData: (business) => business.competitors,
      filterMode: 'text',
      generateFilter: (filter) =>
        // TODO: Regex in API
        ({
          'competitors.name': [filter.value],
        }),
    });

    this.addColumn<string[]>('domain_ssl', {
      columnName: 'SSL',
      dbColumns: ['domains'],
      generateCellData: (business) => {
        return (
          business.domains?.map((dom) => (dom.ssl ? 'SSL' : 'Non-SSL')) ?? []
        );
      },
      filterMode: 'select',
      generateFilter: (filter, cellData) => ({
        'domains.ssl': filter.map((s) => (s === 'ssl' ? true : false)),
      }),
      filterOptions: [
        {
          name: 'SSL',
          value: 'ssl',
        },
        {
          name: 'Non-SSL',
          value: 'non-ssl',
        },
      ],
      width: 180,
    });

    this.addColumn<string[]>('domain_ssl_expiration', {
      columnName: 'SSL Expiration',
      dbColumns: ['domains'],
      generateCellData: (business) => {
        return (
          business.domains?.map((dom) =>
            new Date(dom.ssl_expiration ?? '').toLocaleDateString(),
          ) ?? []
        );
      },
      // TODO: SSL expiration filtering
    });

    this.addColumn<string[]>('domain_analytics_email', {
      columnName: 'Analytics Email',
      dbColumns: ['domains'],
      generateCellData: (business) => {
        return (
          business.domains?.map((dom) => {
            return dom.temp_analytics_email || 'n/a';
          }) ?? []
        );
      },
      filterMode: 'text',
      generateFilter: (filter, cellData) =>
        // TODO: Regex in API
        ({
          'domains.temp_analytics_email': [filter.value],
        }),
    });

    this.addColumn<string[]>('domain_analytics_id', {
      columnName: 'Analytics ID',
      dbColumns: ['domains'],
      generateCellData: (business) => {
        return (
          business.domains?.flatMap((dom) => {
            let analyticsIDs = [];
            if (dom.temp_analytics_id) analyticsIDs.push(dom.temp_analytics_id);
            if (dom.temp_analytics_4_id)
              analyticsIDs.push(dom.temp_analytics_4_id);
            return analyticsIDs.length ? analyticsIDs.join('|') : 'n/a';
          }) ?? []
        );
      },
      filterMode: 'text',
      generateFilter: (filter, cellData) =>
        // TODO: Regex in API
        ({
          'domains.temp_analytics_id': [filter.value],
          'domains.temp_analytics_4_id': [filter.value],
        }),
    });

    this.addColumn<string[]>('domain_static_folder_name', {
      columnName: 'Static Folder Name',
      dbColumns: ['domains'],
      generateCellData: (business) => {
        return (
          business.domains?.map((dom) => {
            return dom.static_folder_name || 'n/a';
          }) ?? []
        );
      },
      filterMode: 'text',
      generateFilter: (filter, cellData) =>
        // TODO: Regex in API
        ({
          'domains.static_folder_name': [filter.value],
        }),
    });

    this.addColumn<string[]>('domain_aem_folder_name', {
      columnName: 'AEM Folder Name',
      dbColumns: ['domains'],
      generateCellData: (business) => {
        return (
          business.domains?.map((dom) => {
            return dom.aem_folder_name || 'n/a';
          }) ?? []
        );
      },
      filterMode: 'text',
      generateFilter: (filter, cellData) =>
        // TODO: Regex in API
        ({
          'domains.aem_folder_name': [filter.value],
        }),
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    });

    this.addColumn<string[]>('domain_ppc_url', {
      columnName: 'PPC URL',
      dbColumns: ['domains'],
      generateCellData: (business) => {
        return (
          business.domains?.map((dom) => {
            return dom.ppc_url || 'n/a';
          }) ?? []
        );
      },
      filterMode: 'text',
      generateFilter: (filter, cellData) =>
        // TODO: Regex in API
        ({
          'domains.ppc_url': [filter.value],
        }),
    });

    this.addColumn<string[]>('domain_ppc_account_id', {
      columnName: 'PPC Account ID',
      dbColumns: ['domains'],
      generateCellData: (business) => {
        return (
          business.domains?.map((dom) => {
            return dom.ppc_account_email || 'n/a';
          }) ?? []
        );
      },
      filterMode: 'text',
      generateFilter: (filter, cellData) =>
        // TODO: Regex in API
        ({
          'domains.ppc_account_email': [filter.value],
        }),
    });

    //==============================================================================

    this.addColumn<string[]>('contact_name', {
      columnName: 'Contact Name',
      dbColumns: ['contacts'],
      generateCellData: (business) => {
        return (
          business.contacts?.map((contact) => {
            return contact.name || 'n/a';
          }) ?? []
        );
      },
      filterMode: 'text',
      generateFilter: (filter, cellData) =>
        // TODO: Regex in API
        ({
          'contacts.name': [filter.value],
        }),
    });

    this.addColumn<string[]>('contact_email', {
      columnName: 'Contact Email',
      dbColumns: ['contacts'],
      generateCellData: (business) => {
        return (
          business.contacts?.map((contact) => {
            return contact.email || 'n/a';
          }) ?? []
        );
      },
      filterMode: 'text',
      generateFilter: (filter, cellData) =>
        // TODO: Regex in API
        ({
          'contacts.email': [filter.value],
        }),
    });

    this.addColumn<string[]>('contact_phone', {
      columnName: 'Contact Phone',
      dbColumns: ['contacts'],
      generateCellData: (business) => {
        return (
          business.contacts?.map((contact) => {
            return contact.phone || 'n/a';
          }) ?? []
        );
      },
      filterMode: 'text',
      generateFilter: (filter, cellData) =>
        // TODO: Regex in API
        ({
          'contacts.phone': [filter.value],
        }),
    });

    this.addColumn<string[]>('contact_mailing_address', {
      columnName: 'Contact Mailing Address',
      dbColumns: ['contacts'],
      generateCellData: (business) => {
        return (
          business.contacts?.map((contact) => {
            return contact.mailing_address || 'n/a';
          }) ?? []
        );
      },
      filterMode: 'text',
      generateFilter: (filter, cellData) =>
        // TODO: Regex in API
        ({
          'contacts.mailing_address': [filter.value],
        }),
    });

    this.addColumn<string[]>('contact_birthday', {
      columnName: 'Contact Birthday',
      dbColumns: ['contacts'],
      generateCellData: (business) => {
        return (
          business.contacts?.map((contact) => {
            return contact.birthday || 'n/a';
          }) ?? []
        );
      },
      // TODO: Date filtering in API
    });

    this.addColumn<string[]>('contact_cmm_anniversary', {
      columnName: 'Contact CMM Anniversary',
      dbColumns: ['contacts'],
      generateCellData: (business) => {
        return (
          business.contacts?.map((contact) => {
            return contact.creekmore_anniversary || 'n/a';
          }) ?? []
        );
      },
      // TODO: Date filtering in API
    });

    this.addColumn<string[]>('contact_biz_anniversary', {
      columnName: 'Contact Business Anniversary',
      dbColumns: ['contacts'],
      generateCellData: (business) => {
        return (
          business.contacts?.map((contact) => {
            return contact.business_anniversary || 'n/a';
          }) ?? []
        );
      },
      // TODO: Date filtering in API
    });

    //====== BUSINESS
    //designer (maybe split "users"?)
    //TODO: Add Content Writer
    //TODO: Add "Content" sidebar

    //BUSINESS quick links? A bit more complex
  }
}
