import { inject, Injectable } from '@angular/core';

import { Note } from '@shared/components/notes/note';
import { Observable, of } from 'rxjs';
import {
  tap,
  catchError,
  shareReplay,
  switchMap,
  skipWhile,
  map,
} from 'rxjs/operators';
import { ConsoleUser, UserPermission, UserRole } from '@helpers/types/user';
import { CmmApiService } from './helpers/cmm-api.service';
import { AuthService } from './auth.service';
import { BasecampProfile, BasecampService } from './basecamp.service';
import {
  APITypeCreating,
  APITypeUpdating,
} from '@helpers/types/api-types.type';
import { CreekmoreTeam } from '@helpers/types/client';

@Injectable({
  providedIn: 'root',
})
export class UserService extends CmmApiService {
  protected override serviceName = 'UserService';

  public user: ConsoleUser | null = null;

  private usersURL = CmmApiService.API_URL + '/users';
  private rolesURL = CmmApiService.API_URL + '/roles';
  private permissionsURL = CmmApiService.API_URL + '/permissions';
  private teamsURL = CmmApiService.API_URL + '/teams';

  private auth = inject(AuthService);
  private basecampService = inject(BasecampService);

  constructor() {
    super();

    this.getUserByID().subscribe((user) => {
      if (!user) {
        throw Error('Error fetching currently logged-in user');
      }

      this.user = user;

      this.basecampService.getProfile().subscribe((profile) => {
        if (!this.user) {
          return;
        }

        // log users out if their Basecamp token can't be found
        if (!profile) {
          return;
        }

        const userDiff = this.getConsoleUserBasecampDiff(user, profile);

        if (Object.keys(userDiff).length > 1) {
          Object.assign(this.user, userDiff);

          this.patchUser(userDiff).subscribe();
        }
      });
    });
  }

  private getConsoleUserBasecampDiff(
    consoleUser: ConsoleUser,
    basecampUser: BasecampProfile,
  ): Partial<ConsoleUser> & { id: number } {
    let user: Partial<ConsoleUser> & { id: number } = {
      id: consoleUser.id,
    };

    if (consoleUser.name !== basecampUser.name) {
      user.name = basecampUser.name;
    }

    if (consoleUser.email !== basecampUser.email_address) {
      user.email = basecampUser.email_address;
    }

    if (consoleUser.basecamp_id !== basecampUser.id) {
      user.basecamp_id = basecampUser.id;
    }

    if (consoleUser.basecamp_sgid !== basecampUser.attachable_sgid) {
      user.basecamp_sgid = basecampUser.attachable_sgid;
    }

    if (consoleUser.title !== basecampUser.title) {
      user.title = basecampUser.title;
    }

    if (consoleUser.bio !== basecampUser.bio) {
      user.bio = basecampUser.bio;
    }

    return user;
  }

  private userByIDObs: Observable<ConsoleUser | null> | null = null;
  getUserByID(platform_id?: string): Observable<ConsoleUser | null> {
    if (!this.userByIDObs) {
      let obs: Observable<Partial<ConsoleUser> | null | undefined>;
      if (!platform_id) {
        obs = this.auth.getUser$();
      } else if (platform_id.startsWith('cmm')) {
        obs = of({ cmm_id: platform_id });
      } else {
        throw Error('Platform ID incorrect format');
      }

      this.userByIDObs = obs.pipe(
        skipWhile((user) => !user),
        switchMap((user) => {
          if (!user) throw Error('User should be specified');

          const platformPath = 'cmm';
          const encodedID = encodeURIComponent(user.cmm_id ?? '');

          const url = `${this.usersURL}/${platformPath}/${encodedID}`;
          return this.http.get<ConsoleUser>(url, this.httpOptions).pipe(
            tap((_) => this.log(`fetched user=${user!.cmm_id}`)),
            catchError(this.handleError<ConsoleUser>(`getUserByID`)),
            this.trace('getUserByID'),
          );
        }),
        shareReplay(1),
      );
    }

    return this.userByIDObs;
  }

  private getAllUsersObs: Observable<ConsoleUser[]> | null = null;
  getAllUsers(): Observable<ConsoleUser[]> {
    const url = `${this.usersURL}`;

    if (!this.getAllUsersObs) {
      this.getAllUsersObs = this.http
        .get<ConsoleUser[]>(url, this.httpOptions)
        .pipe(
          tap((_) => this.log(`retreived all users`)),
          catchError(this.handleError<ConsoleUser[]>(`getAllUsers`)),
          map((users) => users ?? []),
          this.trace('getAllUsers'),
          shareReplay(1),
        );
    }

    return this.getAllUsersObs;
  }

  patchUser(
    user: Partial<ConsoleUser> & { id: number },
  ): Observable<ConsoleUser | null> {
    const url = `${this.usersURL}/${user.id}`;

    return this.http.patch<ConsoleUser>(url, user, this.httpOptions).pipe(
      tap((_) => this.log(`patched user id=${user.id}`)),
      catchError(this.handleError<ConsoleUser>(`patchUser`)),
      this.trace('patchUser'),
    );
  }

  addNote(
    user_id: number,
    data: APITypeCreating<Note>,
  ): Observable<Note | null> {
    const url = `${this.usersURL}/${user_id}/notes`;

    return this.http.post<Note>(url, data, this.httpOptions).pipe(
      tap((_) => this.log(`added note`)),
      catchError(this.handleError<Note>(`addNote`)),
      this.trace('addNote'),
    );
  }

  patchNote(
    user_id: number,
    note_id: number,
    data: APITypeUpdating<Note>,
  ): Observable<Note | null> {
    const url = `${this.usersURL}/${user_id}/notes/${note_id}`;

    return this.http.patch<Note>(url, data, this.httpOptions).pipe(
      tap((_) => this.log(`patched note=${note_id}`)),
      catchError(this.handleError<Note>(`patchNote note=${note_id}`)),
      this.trace('patchNote'),
    );
  }

  deleteNote(user_id: number, note_id: number): Observable<Note | null> {
    const url = `${this.usersURL}/${user_id}/notes/${note_id}`;

    return this.http.delete<Note>(url, this.httpOptions).pipe(
      tap((_) => this.log(`deleted note=${note_id}`)),
      catchError(this.handleError<Note>(`deleteNote note=${note_id}`)),
      this.trace('deleteNote'),
    );
  }

  /**
   *
   * @param queryRoles Array of roles to check in
   * @param role Role to check for
   * @returns If the role was found in the role tree
   */
  private rolesHaveRole(queryRoles: UserRole[], role: UserRole): boolean {
    if (!queryRoles?.length) return false;

    let hasRole = queryRoles.some((r) => r.id === role.id);

    return (
      hasRole ||
      queryRoles.some((r) => this.rolesHaveRole(r.managed_roles, role))
    );
  }

  userHasRole(role: UserRole, user?: ConsoleUser): boolean {
    return this.rolesHaveRole((user ?? this.user)?.roles ?? [], role);
  }

  private getAllRolesObs: Observable<UserRole[]> | null = null;
  getAllRoles(): Observable<UserRole[]> {
    const url = `${this.rolesURL}`;

    if (!this.getAllRolesObs) {
      this.getAllRolesObs = this.http
        .get<UserRole[]>(url, this.httpOptions)
        .pipe(
          tap((_) => this.log(`retreived all roles`)),
          catchError(this.handleError<UserRole[]>(`getRoles`)),
          map((roles) => roles ?? []),
          this.trace('getRoles'),
          shareReplay(1),
        );
    }

    return this.getAllRolesObs;
  }

  patchRoleUsers(
    role_id: number,
    users: ConsoleUser[],
  ): Observable<UserRole | null> {
    const url = `${this.rolesURL}/${role_id}/users`;

    let user_data = {
      user_id: users.map((p) => p.id),
    };

    return this.http.patch<UserRole>(url, user_data, this.httpOptions).pipe(
      tap((_) => this.log(`patched users role=${role_id}`)),
      catchError(this.handleError<UserRole>(`patchRoleUsers role=${role_id}`)),
      this.trace('patchRoleUsers'),
    );
  }

  patchRolePermissions(
    role_id: number,
    permissions: UserPermission[],
  ): Observable<UserRole | null> {
    const url = `${this.rolesURL}/${role_id}/permissions`;

    let permission_data = {
      permission_id: permissions.map((p) => p.id),
    };

    return this.http
      .patch<UserRole>(url, permission_data, this.httpOptions)
      .pipe(
        tap((_) => this.log(`patched permissions role=${role_id}`)),
        catchError(
          this.handleError<UserRole>(`patchRolePermissions role=${role_id}`),
        ),
        this.trace('patchRolePermissions'),
      );
  }

  addPermission(data: UserPermission): Observable<UserPermission | null> {
    const url = `${this.permissionsURL}`;

    return this.http.post<UserPermission>(url, data, this.httpOptions).pipe(
      tap((_) => this.log(`added permission`)),
      catchError(this.handleError<UserPermission>(`addPermission`)),
      this.trace('addPermission'),
    );
  }

  public hasPermission(permissionName: string): boolean {
    return (
      this.user?.permissions?.some(
        (permission) => permission === permissionName,
      ) ?? false
    );
  }

  patchUserTeams(user: ConsoleUser): Observable<CreekmoreTeam[] | null> {
    const url = `${this.usersURL}/${user.id}/teams`;

    let data = {
      team_id: user.teams.map((team) => team.id),
    };

    return this.http.patch<CreekmoreTeam[]>(url, data, this.httpOptions).pipe(
      tap((_) => this.log(`added permission`)),
      catchError(this.handleError<CreekmoreTeam[]>(`addPermission`)),
      this.trace('addPermission'),
    );
  }

  addTeam(
    team: APITypeCreating<CreekmoreTeam, 'members'>,
  ): Observable<CreekmoreTeam | null> {
    const url = `${this.teamsURL}`;

    return this.http.post<CreekmoreTeam>(url, team, this.httpOptions).pipe(
      tap((_) => this.log(`added note`)),
      catchError(this.handleError<CreekmoreTeam>(`addTeam`)),
      this.trace('addTeam'),
    );
  }

  patchTeam(
    team: APITypeUpdating<CreekmoreTeam, 'members'>,
  ): Observable<CreekmoreTeam | null> {
    const url = `${this.teamsURL}/${team.id}`;

    return this.http.patch<CreekmoreTeam>(url, team, this.httpOptions).pipe(
      tap((_) => this.log(`patched team=${team.id}`)),
      catchError(this.handleError<CreekmoreTeam>(`patchTeam team=${team.id}`)),
      this.trace('patchTeam'),
    );
  }

  deleteTeam(
    team: APITypeUpdating<CreekmoreTeam>,
  ): Observable<CreekmoreTeam | null> {
    const url = `${this.teamsURL}/${team.id}`;

    return this.http.delete<CreekmoreTeam>(url, this.httpOptions).pipe(
      tap((_) => this.log(`deleted team=${team.id}`)),
      catchError(this.handleError<CreekmoreTeam>(`deleteTeam team=${team.id}`)),
      this.trace('deleteTeam'),
    );
  }
}
